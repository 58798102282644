import React from 'react';
import {
  Route,
  Redirect,
  useLocation,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

/* Theme */
import './theme/variables.css';
import './theme/elements.css';

import { LiveQuizContext, LiveQuiz } from './contexts/LiveQuiz';
import Entry from './pages/Entry/Entry';
import Game from './pages/Game/Game';

const Routes = () => {
  const location = useLocation();
  const [rootPath] = location.pathname.substring(1).split("/");

  return (
    <LiveQuizContext.Consumer>
      {liveQuiz => (
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={rootPath}>
          {liveQuiz.isEntried &&
            <Route exact path="/game" component={Game} />
          }
            <Route exact path="/:eventCode" component={Entry} />
            <Route exact path="/" component={Entry} />
            <Redirect from="*" to="/" />
          </Switch>
        </AnimatePresence>
      )}
    </LiveQuizContext.Consumer>
  );
}

function App() {
  return (
    <LiveQuizContext.Provider value={new LiveQuiz()}>
      <Router>
        <Routes />
      </Router>
    </LiveQuizContext.Provider>
  );
}

export default App;
