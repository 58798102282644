import React, { Component } from 'react';
import styles from './Entry.module.css';
import { motion } from "framer-motion";
import { Howl } from 'howler';
import { LiveQuizContext } from '../../contexts/LiveQuiz';

class Entry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendCode: '',
      eventCode: '',
      password: '',
      executing: false,
      showToast: false,
      messages: []
    };

    this.tapSE = new Howl({
      src: ["/snd/tapSE.mp3"]
    });
  }

  get LiveQuiz() {
    return this.context;
  }

  componentDidMount() {
    const {params} = this.props.match;
    let eventCode = params.eventCode;
    if (!eventCode || eventCode === 'game') {
      eventCode = '';
    }

    const fromEntries = iterable => {
      return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {})
    }
    const getQuery = search => {
      const searchParams = new URLSearchParams(search);
      if (Object.fromEntries) {
        return Object.fromEntries(searchParams);
      }
      return fromEntries(searchParams);
    };
    const query = getQuery(this.props.location.search);

    const getFriendCode = query => {
      if (query.code) {
        return query.code;
      }
      const code = localStorage.getItem("code");
      if (code) {
        return code;
      }
      return '';
    };
    const friendCode = getFriendCode(query);

    this.setState({
      friendCode: friendCode,
      eventCode: eventCode
    });

    const entryData = this.LiveQuiz.entryData;
    if (entryData && entryData.friendCode && entryData.eventCode) {
      if ((friendCode === '' || friendCode === entryData.friendCode)
        && (eventCode === '' || eventCode === entryData.eventCode)) {
        this.showToast("参加中のイベントにもどります", {
          yesLabel: '実行',
          yes: this.restore.bind(this)
        });
      }
    }
  }

  changeFriendCode(event) {
    this.setState({ friendCode: event.target.value });
  }

  changeEventCode(event) {
    this.setState({ eventCode: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  getErrorMessage(error) {
    const message = error.message;
    if ("Invalid parameter" === message) {
      return "パラメータがちがいます";
    }
    if ("The event has not been held" === message) {
      return "イベントはかいさいされていません";
    }
    if ("The event has ended" === message) {
      return "イベントはおわりました";
    }
    if ("Over capacity" === message) {
      return "さんか人数がいっぱいです";
    }
    if ("Password doesn't match" === message) {
      return "ひみつのばんごうが違います";
    }
    if ("User doesn't exists" === message) {
      return "フレンドコードがありません";
    }
    if ("User already has been enteried" === message) {
      return "フレンドコードはもう使われています";
    }
    if ("This device already has been registered" === message) {
      return "この端末はもうエントリーしています";
    }
    if ("This device has not been registered" === message) {
      return "この端末はエントリーしてません";
    }
    if ("Restore has been faild" === message) {
      return "イベントにもどれませんでした"
    }
    return message;
  }

  showToast(message, header = null) {
    const messages = this.state.messages.slice();
    messages.unshift(message);
    this.setState({
      showToast: true,
      messages: messages,
      toastHeader: header
    });

    if (header === null) {
      setTimeout(() => {
        this.hideToast();
      }, 3000);  
    }
  }

  hideToast() {
    if (this.state.messages.length <= 1) {
      this.setState({ showToast: false, toastHeader: null });
    } else {
      this.removeToastMessage();
    }
  }

  onToastAnimationEnd() {
    if (!this.state.showToast) {
      this.removeToastMessage();
    }
  };

  onClickToastYes() {
    this.hideToast();
    if (this.state.toastHeader && this.state.toastHeader.yes) {
      this.state.toastHeader.yes();
    }
  }

  onClickToastNo() {
    this.hideToast();
    if (this.state.toastHeader && this.state.toastHeader.no) {
      this.state.toastHeader.no();
    }
  }

  removeToastMessage() {
    const messages = this.state.messages.slice();
    messages.pop();
    this.setState({ messages: messages });
  }

  async entry() {
    try {
      this.tapSE.play();
      this.setState({executing : true});

      const {friendCode, eventCode, password} = this.state;
      await this.LiveQuiz.entry(eventCode, friendCode, password);

      localStorage.setItem("code", friendCode);

      this.props.history.push({ pathname: '/game' });
    } catch (e) {
      console.log(e);
      this.setState({executing : false});
      this.showToast(this.getErrorMessage(e));
    }
  }

  async restore() {
    try {
      this.tapSE.play();
      this.setState({executing : true});

      await this.LiveQuiz.restore();

      this.props.history.push({ pathname: '/game' });
    } catch (e) {
      console.log(e);
      this.setState({executing : false});
      this.showToast(this.getErrorMessage(e));
    }
  }

  render() {
    const {
      friendCode,
      eventCode,
      password,
      executing,
      showToast,
      toastHeader,
      messages
    } = this.state;

    return (
      <motion.div className={styles.container}
        animate={{
          y: 0,
          opacity: 1
        }}
        initial={{
          y: -100,
          opacity: 0
        }}
        exit={{
          y: 100,
          opacity: 0
        }}
        transition={{
          duration: 0.8
        }}
      >
        <div className={styles.header}>
          <img src="/img/logo.png"
            width="512" height="427"
            alt="実況！マグパー英語クイズ"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            <label htmlFor="friendCode">
              フレンドコード
              <input
                type="text"
                name="friendCode"
                value={friendCode}
                onChange={this.changeFriendCode.bind(this)}
              />
            </label>
          </div>
          <div className={styles.item}>
            <label htmlFor="eventCode">
              イベントコード
              <input
                type="text"
                name="eventCode"
                value={eventCode}
                onChange={this.changeEventCode.bind(this)}
              />
            </label>
          </div>
          <div className={styles.item}>
            <label htmlFor="password">
              ひみつのばんごう
              <input
                type="number"
                name="password"
                value={password}
                onChange={this.changePassword.bind(this)}
              />
            </label>
          </div>
          <div className={styles.item}>
            <button
              disabled={executing || showToast}
              onClick={() => this.entry()}
              onTouchStart={()=>{}}
            >{!executing ?
              <>ENTRY</> :
              <>LOADING<div className={styles.loading}>
                {[...Array(12)].map((v, i) =>
                  <div key={`loading-circle-${i}`} className={styles.circle}></div>
                )}
                </div></>
            }</button>
          </div>
        </div>
        <div className={styles.footer}>© MINTFLAG Inc.  All Rights Reserved.</div>
      {messages.length > 0 &&
        <div
          className={`${styles.toast} ${showToast ? styles.showToast : styles.hideToast}`}
          onAnimationEnd={this.onToastAnimationEnd.bind(this)}
        >
          <div>{messages[0]}</div>
        {toastHeader !== null &&
          <div>
          {toastHeader.yes &&
            <button onClick={() => this.onClickToastYes()}>{toastHeader.yesLabel ? toastHeader.yesLabel : 'はい'}</button>
          }
          {toastHeader.no &&
            <button onClick={() => this.onClickToastNo()}>{toastHeader.noLabel ? toastHeader.yesLabel : 'いいえ'}</button>
          }
          </div>
        }
        </div>
      }
      </motion.div>
    );
  }
}

Entry.contextType = LiveQuizContext;
export default Entry;