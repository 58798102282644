import React, { Component } from 'react';
import styles from './Game.module.css';
import Countdown from '../../components/Countdown/Countdown';
import Confetti from 'react-confetti';
import { motion } from "framer-motion";
import { Howl } from 'howler';
import { LiveQuizContext, LiveQuiz } from '../../contexts/LiveQuiz';

const COWNTDOWN_TIME = 9 * 1000;

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      status: 'start',
      questionCount: 0,
      questionSource: '',
      questionContent: '',
      questionImage: '',
      point: 0,
      action: false,
      yesSelected: '',
      noSelected: '',
      score: 0,
      ranking: 0,
      numOfRankings: 0,
      winningNumber: '',
      lotteryTargets: '',
      lotteryElected: false
    };

    this.countdownRef = React.createRef();

    this.tapSE = new Howl({
      src: ["/snd/tapSE.mp3"]
    });
    this.questionSE = new Howl({
      src: ["/snd/questionSE.mp3"]
    });
    this.cowntdownSE = new Howl({
      src: ["/snd/cowntdownSE.mp3"]
    });
    this.rightSE = new Howl({
      src: ["/snd/rightSE.mp3"]
    });
    this.wrongSE = new Howl({
      src: ["/snd/wrongSE.mp3"]
    });
    this.winSE = new Howl({
      src: ["/snd/winSE.mp3"]
    });
    this.loseSE = new Howl({
      src: ["/snd/loseSE.mp3"]
    });
  }

  get LiveQuiz() {
    return this.context;
  }

  componentDidMount() {
    this.LiveQuiz.setup({
      onQuestion: (question)=>{
        this.recieveQuestion(question);
      },
      onAnswer: (correct)=>{
        this.recieveAnswer(correct);
      },
      onLottery: (winnerId, targets)=>{
        this.recieveLottery(winnerId, targets);
      },
      onTeardown: ()=>{
        this.recieveTeardown();
      },
      onWin: (winningNumber)=>{
        this.recieveWin(winningNumber);
      }
    });
    const update = {
      id: this.LiveQuiz.id,
      status: 'start',
      questionCount: 0,
      questionSource: '',
      questionContent: '',
      questionImage: '',
      point: 0,
      action: false,
      yesSelected: '',
      noSelected: '',
      score: 0,
      ranking: 0,
      numOfRankings: 0,
      winningNumber: '',
      lotteryTargets: '',
      lotteryElected: false
    };
    this.setState(update);
  }

  recieveQuestion(question) {
    const answer = this.LiveQuiz.currentAnswer;
    const update = {
      status: '',
      questionCount: this.LiveQuiz.questionCount,
      questionSource: question.source,
      questionContent: question.content,
      questionImage: question.contentImage,
      point: question.point,
      action: true,
      yesSelected: answer === 'YES' ? styles.selected : '',
      noSelected: answer === 'NO' ? styles.selected : ''
    };
    this.setState(update);

    const start = new Date(question.timestamp);
    const timeLimit = question.timeLimits * 1000 - (Date.now() - start);
    this.countdownRef.current.start(timeLimit > 0 ? timeLimit : 0);
    if (timeLimit > 0) {
      this.countdownRef.current.start(timeLimit);
      this.questionSE.play();

      if (timeLimit > COWNTDOWN_TIME) {
        setTimeout(()=>{
          this.cowntdownSE.play();
        }, timeLimit - COWNTDOWN_TIME)
      }
    } else {
      this.timeout();
    }
  }

  timeout() {
    this.setState({ action: false });
  }

  async answer(value) {
    const update = { action: false };
    update[`${value.toLowerCase()}Selected`] = styles.selected;

    this.setState(update);
    this.tapSE.play();

    await this.LiveQuiz.answer(value);
  }

  recieveAnswer(correct) {
    const update = {
      status: correct ? 'right' : 'wrong',
      score: this.LiveQuiz.score,
      ranking: this.LiveQuiz.ranking,
      numOfRankings: this.LiveQuiz.numOfRankings,
    };
    this.setState(update);

    if (correct) {
      this.rightSE.play();
    } else {
      this.wrongSE.play();
    }
  }

  recieveLottery(winnerId, targets) {
    const win = this.state.id === winnerId;
    const update = {
      status: win ? 'win' : 'lose',
      lotteryTargets: targets
    }
    if (win && targets === LiveQuiz.LOTTARY_TARGETS_UNCELECTED) {
      update.lotteryElected = true;
    }
    this.setState(update);

    if (win) {
      this.winSE.play();
    } else if (!this.LiveQuiz.winningNumber) {
      this.loseSE.play();
    }
  }

  recieveWin(winningNumber) {
    this.setState({ winningNumber: winningNumber });
  }

  recieveTeardown() {
    const update = {
      id: 0,
      status: 'end',
      questionSource: '',
      questionContent: '',
      questionImage: '',
      action: false,
      yesSelected: '',
      noSelected: ''
    };
    this.setState(update);
    this.countdownRef.current.reset();
  }

  end() {
    this.props.history.goBack();
  }

  render() {
    const {
      id,
      status,
      questionCount,
      questionSource,
      questionContent,
      questionImage,
      action,
      yesSelected,
      noSelected,
      score,
      ranking,
      numOfRankings,
      point,
      winningNumber,
      lotteryTargets,
      lotteryElected
    } = this.state;

    const elected = lotteryElected && lotteryTargets === LiveQuiz.LOTTARY_TARGETS_UNCELECTED;
    const overlay = status !== 'start' && status !== '';
    const answerStyle = status === 'right'
                      ? styles.right : status === 'wrong'
                      ? styles.wrong : '';
    return (
      <motion.div className={styles.container}
        animate={{
          y: 0,
          opacity: 1
        }}
        initial={{
          y: -100,
          opacity: 0
        }}
        exit={{
          y: 100,
          opacity: 0
        }}
        transition={{
          duration: 0.8
        }}
      >      
        <div className={styles.header}>
          <div className={styles.entry}
          ><span><small>NO.</small>{id}</span></div>
          <div className={styles.status}>
            <div className={styles.score}
            >{numOfRankings > 0 ? score : 'スコア'}</div>
            <div className={styles.ranking}
            >{numOfRankings > 0 ?　`${ranking}/${numOfRankings}` : "順位"}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <span
              style={{ visibility: questionCount > 0 ? 'visible' : 'hidden' }}
            >第{questionCount}問</span>
            <div className={styles.point}
              style={{ visibility: point > 0 ? 'visible' : 'hidden' }}
            >{point}</div>
          </div>
          <div className={styles.source}
            style={{ visibility: questionSource !== '' ? 'visible' : 'hidden' }}
          >{questionSource}</div>
          <div className={styles.question}>
            {questionImage &&
              <img src={questionImage} alt="問題画像" />
            }
            {status === 'start' ? <>クイズが始まるまで<br />少々お待ちください</> : <>{questionContent}</> }
          </div>
          <div className={styles.countdownContainer}>
            <Countdown ref={this.countdownRef} finish={() => this.timeout()} />
          </div>
        </div>
        <div className={styles.footer}>
          <button className={`${styles.yes} ${yesSelected}`}
            disabled={!action && !yesSelected}
            onClick={() => this.answer('YES')}
            onTouchStart={()=>{}}
          >YES</button>
          <button className={`${styles.no} ${noSelected}`}
            disabled={!action && !noSelected}
            onClick={() => this.answer('NO')}
            onTouchStart={()=>{}}
          >NO</button>
        </div>
        <div className={styles.overlay} style={{ display: overlay ? 'flex' : 'none' }}>
        {/**** for answer ****/}
        {status === 'right' &&
          <div className={`${styles.answer} ${answerStyle}`}>
            <img src="/img/icon_right.svg" width="160" height="160" alt="◯" />
            <p>せいかい</p>
          </div>
        }
        {status === 'wrong' &&
          <div className={`${styles.answer} ${answerStyle}`}>
            <img src="/img/icon_wrong.svg" width="160" height="160" alt="✕" />
            <p>まちがい</p>
          </div>
        }
        {/**** for lottery ****/}
        {status === 'win' &&
          <div style={{width:'100vw', display:'flex', justifyContent:'center'}}>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
            />      
            <div className={`${styles.lottery} ${styles.win}`}>
              <p><span role="img" aria-label="やった!">🎯</span>あたり‼</p>
              <div className={styles.winningNumber}>{winningNumber}</div>
            </div>
          </div>
        }
        {status === 'lose' && elected &&
          <div className={`${styles.lottery} ${styles.won}`}>
            <p><span role="img" aria-label="やった!">🎯</span>当選ずみ</p>
          </div>
        }
        {status === 'lose' && !elected &&
          <div className={`${styles.lottery} ${styles.lose}`}>
            <p>ハズレ<span role="img" aria-label="ざんねん">💧</span></p>
          </div>
        }
        {/**** for end ****/}
        {status === 'end' &&
          <div className={styles.end}>
            <p>おしまい!</p>
            <button
              onClick={() => this.end()}
              onTouchStart={()=>{}}
            >OK</button>
          </div>
        }
        </div>
      </motion.div>
    );
  }
}

Game.contextType = LiveQuizContext;
export default Game;