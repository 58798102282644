import React, { Component } from 'react';
import styles from './Countdown.module.css';

export default class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = { width: '0%' };
  }

  start(timeLimit) {
    if (this.intervalID) {
      clearInterval(this.intervalID); 
    }

    const fps = 30;
    const startTime = new Date();
    let sec = Math.ceil(timeLimit / 1000);
    this.intervalID = setInterval(()=>{
      const duration = new Date().getTime() - startTime.getTime();
      const ratio = (1 - duration / timeLimit) * 100;
      this.setState({width: ratio > 0 ? `${ratio}%` : `0%`});

      if (this.props.update) {
        const newSec = Math.ceil((timeLimit - duration) / 1000);
        if (sec !== newSec) {
          this.props.update(newSec);
          sec = newSec;
        }
      }

      if (duration > timeLimit) {
        clearInterval(this.intervalID);
        this.intervalID = null;
        this.props.finish();
      }
    }, 1000 / fps);
  }

  reset() {
    if (this.intervalID) {
      clearInterval(this.intervalID); 
    }
    this.setState({width: `100%`});
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.progress} style={{width: this.state.width}}></div>
      </div>
    );
  }
}
