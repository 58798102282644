import { createContext } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

export const LiveQuizContext = createContext();

export class LiveQuiz {
  static get LOTTARY_TARGETS_UNCELECTED () {
    return 'unelected';
  }
  static get LOTTARY_TARGETS_ALL () {
    return 'all';
  }
  static get MAX_RESTORE_PERIOD () {
    return 24 * 60 * 60 * 1000;
  }

  constructor() {
    this._initProperty();
  }

  get isEntried() {
    return this.id !== 0;
  }

  get questionCount() {
    return Object.keys(this.questions).length;
  }

  get numOfRankings() {
    return this.rankings ? this.rankings.length : 0;
  }

  get currentQuestion() {
    const key = this.currentQuestionKey;
    return key ? this.questions[key] : null;
  }

  get currentAnswer() {
    const key = this.currentQuestionKey;
    return key ? this.answers[key] : null;
  }

  get entryData() {
    const data = JSON.parse(localStorage.getItem("LiveQuizEntryData"));
    if (!data || !data.timestamp) {
      return {};
    }
    if (Date.now() - data.timestamp > LiveQuiz.MAX_RESTORE_PERIOD) {
      this._removeEntryData();
      return {};
    }
    return data;
  }

  _saveEntryData(eventCode, friendCode, password) {
    const entryData = JSON.stringify({
      eventCode: eventCode,
      friendCode: friendCode,
      password: password,
      timestamp: Date.now()
    });
    localStorage.setItem("LiveQuizEntryData", entryData);
  }

  _removeEntryData() {
    localStorage.removeItem("LiveQuizEntryData");
  }

  async entry(eventCode, friendCode, password, callbacks) {
    if (!eventCode || !friendCode || !password) {
      throw new Error("Invalid parameter");
    }
    this._initProperty(eventCode, friendCode, password, callbacks);

    await this._initEntry(eventCode);

    const entrySnapshot = await this.entryRef.once('value');
    if (entrySnapshot.exists()) {
      const val = entrySnapshot.val();
      if (val.id) {
        if (val.post.friendCode === friendCode && val.post.password === password) {
          this._saveEntryData(eventCode, friendCode, password);
          await this.restore(callbacks);
          return;
        } else {
          throw new Error("This device already has been registered");
        }
      }
    }

    await this.entryRef.remove();
    await this._postEntry();
    await new Promise(this._waitEntryResponse.bind(this));

    this._prepare();

    this._saveEntryData(eventCode, friendCode, password);

    return this.id;
  }

  _initProperty(
    eventCode = null,
    friendCode = null,
    password = null, 
    callbacks = {
      onQuestion: (question)=>{},
      onAnswer: (correct)=>{},
      onLottery: (winnerId, targets)=>{},
      onTeardown: ()=>{},
      onWin: (winningNumber)=>{}
    }
  ) {
    this.eventCode = eventCode;
    this.friendCode = friendCode;
    this.password = password;
    this.entryRef = null;
    this.id = 0;
    this.questions = {};
    this.answers = {};
    this.currentQuestionKey = null;
    this.score = 0;
    this.ranking = 0;
    this.rankings = null;
    this.winningNumbers = [];
    this.timestamp = 0;
    this.callbacks = callbacks;
  }

  async _initEntry(eventCode) {
    const auth = firebase.auth();
    const userCredential = await auth.signInAnonymously();
    const user = userCredential.user;

    const database = firebase.database();

    const eventRef = database.ref(
      `livequiz/events/${eventCode}`
    );

    const startAtSnapshot = await eventRef.child('startAt').once('value');
    if (!startAtSnapshot.exists()) {
      throw new Error("The event has not been held");
    }

    const endAtSnapshot = await eventRef.child('endAt').once('value');
    if (endAtSnapshot.exists()) {
      throw new Error("The event has ended");
    }

    this.entryRef = database.ref(
      `livequiz/events/${eventCode}/entries/${user.uid}`
    );
  }

  _postEntry() {
    return this.entryRef.child('/post').set({
      friendCode: this.friendCode,
      password: this.password,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });
  }

  _waitEntryResponse(resolve, reject) {
    this.entryRef.on('value', async (dataSnapshot)=>{
      const val = dataSnapshot.val();
      if (!val) {
        await this._postEntry();
      }
      else if (val && val.error) {
        this.entryRef.off();
        reject(new Error(val.error));
      }
      else if (val.id > 0) {
        this.id = val.id;
        this.entryRef.off();
        resolve(this.id);
      }
    });
  }

  _prepare() {
    const database = firebase.database();
    database.ref(
      `livequiz/events/${this.eventCode}/questions/`
    ).on('child_added', this._passQuestion.bind(this));
    database.ref(
      `livequiz/events/${this.eventCode}/answers/`
    ).on('child_added', this._passAnswer.bind(this));
    database.ref(
      `livequiz/events/${this.eventCode}/lotteries/`
    ).on('child_added', this._passLottery.bind(this));
    database.ref(
      `livequiz/events/${this.eventCode}/endAt/`
    ).on('value', this._passTeardown.bind(this));

    this.responsesRef = database.ref(
      `livequiz/events/${this.eventCode}/responses/`
    );

    this.entryRef.on('value', this._passEntry.bind(this));

    const storage = firebase.storage();
    this.questionImgRef = storage.ref('img/questions');
  }

  async _passQuestion(childSnapshot, prevChildKey) {
    const key = childSnapshot.key;
    const val = childSnapshot.val();

    const img = val.contentImage;
    if (img) {
      try {
        const ref = this.questionImgRef.child(`${img}.png`);
        val.contentImage = await ref.getDownloadURL();  
      } catch(error) {
        console.error(error);
      }
    }

    this.currentQuestionKey = key;
    this.questions[key] = val;
    if (this.timestamp < val.timestamp) {
      this.timestamp = val.timestamp;
      this.callbacks.onQuestion(val);
    }
  }

  _passAnswer(childSnapshot, prevChildKey) {
    const key = childSnapshot.key;
    const val = childSnapshot.val();

    const question = this.questions[key];
    if (question) {
      const rankings = val.rankings;
      this.rankings = rankings;

      const entry = rankings.find(v => v.id === this.id);
      if (entry) {
        this.score = entry.score;
        this.ranking = entry.ranking;  
      }

      if (this.timestamp < val.timestamp && this.currentQuestionKey === key) {
        this.timestamp = val.timestamp;
        const correct = val.answer === this.answers[key];
        this.callbacks.onAnswer(correct);
        this.currentQuestionKey = null;
      }
    }
  }

  _passLottery(childSnapshot, prevChildKey) {
    const val = childSnapshot.val();
    if (this.timestamp < val.timestamp) {
      this.timestamp = val.timestamp;
      this.callbacks.onLottery(val.winnerId, val.targets);
    }
  }

  _passTeardown(dataSnapshot) {
    const val = dataSnapshot.val();
    if (val) {
      this._teardown();
    }
  }

  _passEntry(dataSnapshot) {
    const val = dataSnapshot.val();
    let next = this._passRemove;
    while (next !== null) {
      next = (next.bind(this))(val);
    }
  }

  _passRemove(val) {
    if (!val) {
      this._teardown();
      return null;
    }
    return this._passWin;
  }

  _passWin(val) {
    if (!val || !val.winningNumbers) {
      return null;
    }
    const winningNumbers = val.winningNumbers;

    const array = Object.keys(winningNumbers).map((key)=>{
      return winningNumbers[key];
    });

    if (this.winningNumbers.length < array.length) {
      array.sort((a, b) => b.timestamp - a.timestamp);
      this.winningNumbers = array.slice();
      const first = this.winningNumbers[0];
      this.callbacks.onWin(first.winningNumber);
    }

    return null;
  }

  _teardown() {
    this.entryRef.off();
    const database = firebase.database();
    database.ref(
      `livequiz/events/${this.eventCode}/questions/`
    ).off();
    database.ref(
      `livequiz/events/${this.eventCode}/answers/`
    ).off();
    database.ref(
      `livequiz/events/${this.eventCode}/lotteries/`
    ).off();
    database.ref(
      `livequiz/events/${this.eventCode}/endAt/`
    ).off();
    this.id = 0;
    this._removeEntryData();
    this.callbacks.onTeardown();
  }

  setup(value) {
    this.callbacks = value;
  }

  async answer(value) {
    if (!this.currentQuestionKey) {
      throw new Error('The question is unknown');
    }
    const key = this.currentQuestionKey;
    await this.entryRef.child(`post/answers/${key}`).set({
      value: value,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    this.answers[key] = value;
  }

  async restore(callbacks) {
    const { eventCode, friendCode, password } = this.entryData;
    if (!eventCode || !friendCode || !password) {
      throw new Error("Invalid parameter");
    }

    try {
      this._initProperty(eventCode, friendCode, password);

      await this._initEntry(eventCode);
  
      const entrySnapshot = await this.entryRef.once('value');
      const val = entrySnapshot.val();
      if (!val.id) {
        throw new Error("This device has not been registered");
      }
      this.id = val.id;
  
      const answers = val.post.answers;
      if (answers) {
        Object.keys(answers).forEach((key)=>{
          this.answers[key] = answers[key].value;
        });
      }
  
      this._prepare();
  
      if (callbacks) {
        this.setup(callbacks);
      }
  
      return this.id;
    } catch(error) {
      this._removeEntryData();
      throw new Error("Restore has been faild");
    }
  }
}